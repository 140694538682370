@import 'system/variables';
@import 'system/mixin';
body {
  background-color: #edeff4;
  color: #9a9da2;
  font-size: 15px;
  line-height: 26px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #31404b;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
a:hover,
a:focus {
  text-decoration: none;
}
.container {
  max-width: 1186px;
}
.header {
  &__top-bar {
    background-color: #292c31;
  }
  &__top-bar-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
  }
  &__secondary {
    display: block;
    text-align: right;
    background-color: #1e2024;
  }
  &__primary {
    background-color: #1e2024;
    &-inner {
      min-height: 62px;
      position: relative;
      z-index: 99;
      background-color: #292c31;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 210px;
        background-color: #1e2024;
        left: -25px;
        margin-left: 0;
        -webkit-transform: skew(-25deg);
        transform: skew(-25deg);
        display: block;
      }
    }
  }
}
.header-logo {
  position: absolute;
  z-index: 3;
  left: 0;
  display: block;
  bottom: 26px;
  width: 180px;
  img {
    height: auto;
    max-width: 100%;
    position: relative;
  }
}
.header-cart-dropdown {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  width: 360px;
  background-color: #fff;
  opacity: 0;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-75deg);
  transform: rotateX(-75deg);
  visibility: hidden;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
}
.header-search-form {
  background-color: transparent;
  width: 360px;
  display: inline-block;
  padding: 24px 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 1;
  vertical-align: top;
}
.search-form {
  position: relative;
  &__form-control {
    border: 1px solid #e4e7ed;
    line-height: 1.733333;
    width: 100%;
    background-color: #292c31;
    border-color: #292c31;
    color: rgba(154, 157, 162, 0.6);
    border-radius: 20px;
    height: 38px;
    padding: 6px 45px 6px 20px;
    font-size: 12px;
    box-shadow: none;
    transition: border-color ease-in-out 0.15s;
  }
  &__submit {
    color: #fff;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 51px;
    height: 38px;
    line-height: 38px;
    padding: 0;
    border: none;
    font-size: 16px;
    transition: color, background-color 0.3s ease;
    border-radius: 0 20px 20px 0;
  }
}
.nav-account {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  width: auto;
  &__item {
    font-family: 'Montserrat', sans-serif;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5em;
    transition: all 0.3s ease;
    display: inline-block;
    color: #fff;
    &--slash {
      &:before {
        content: '\002f';
        display: inline-block;
        color: #6b6d70;
        margin: 0 16px 0 14px;
      }
    }
  }
}
.info-block {
  list-style: none;
  padding: 0;
  margin: 0;
  &__item {
    margin-left: 20px;
    position: relative;
    min-height: 26px;
    padding: 2px 0 0 40px;
    margin-bottom: 34px;
    list-style: none;
    text-align: left;
    &.has-children {
      .info-block__link-wrapper {
        padding-right: 16px;
        &:after {
          content: '\f107';
          display: block;
          position: absolute;
          right: 0;
          top: 23px;
          font-size: 10px;
          line-height: 1.2em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: 'Font Awesome 5 Free';
          font-weight: 700;
          color: #fff;
        }
      }
      &:hover .header-cart-dropdown {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__heading {
    font-size: 9px;
    line-height: 1.2em;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  &__link {
    font-family: 'Montserrat', sans-serif;
    display: block;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 1.2em;
    color: #6b6d70;
    vertical-align: top;
    -webkit-transition: color 0.25s cubic-bezier(0.3, 0.1, 0.55, 1);
    transition: color 0.25s cubic-bezier(0.3, 0.1, 0.55, 1);
    &:hover {
      color: #fff;
    }
  }
  &__link-wrapper {
    display: block;
  }
  &__cart-sum {
    font-family: 'Montserrat', sans-serif;
    color: #c2ff1f;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 1.2em;
    display: block;
  }
  &--header {
    display: inline-block;
    vertical-align: middle;
    .info-block__item {
      padding-top: 20px;
      padding-bottom: 32px;
      margin-left: 20px;
      display: inline-block;
      margin: 7px 0 0 20px;

      .df-icon {
        stroke: #c2ff1f;
        top: 24px;
        &-stack--bag {
          top: 3px;
          display: block;
          position: absolute;
          left: 0;
        }
        &--bag-handle {
          top: 19px;
          left: 0%;
          transform: translate(40%, 0);
          width: 12px;
          height: 11px;
          stroke-width: 1.6px;
        }
      }
    }
    .info-block__heading {
      margin-top: 5px;
      color: #fff;
    }
  }
  &--shopping-cart {
    .info-block__heading {
      margin-bottom: 3px;
    }
  }
}
.df-icon {
  width: 28px;
  height: 28px;
  top: 24px;
  stroke: #c2ff1f;
  display: block;
  position: absolute;
  left: 0;
  fill: none;
  &--whistle {
    width: 30px;
    height: 32px;
    stroke-width: 1.8px;
  }
  &--soccer-ball {
    stroke-width: 1.8px;
  }
  &--bag {
    // left: 50% !important;
    top: 24px;
    //    transform: translate(-50%, 0);
    width: 22px;
    height: 24px;
    stroke-width: 1.6px;
  }
  &--bag-handle {
    transform: translate(40%, 0);
  }
}

.widget_shopping_cart_content {
  .cart_list {
    .empty {
      font-size: 14px;
      line-height: 22px;
      padding: 24px 24px;
    }
  }
}

.main-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  ul[class*='megamenu-col-'] {
    overflow: hidden;
  }
  .header-mobile__logo {
    display: none;
  }
  &__list {
    border-left: none;
    display: flex;
    text-align: left;
    padding: 0;
    margin: 0;
    list-style: none;
    &-item {
      text-align: center;
      display: inline-block;
      padding: 0;
      position: relative;
      &.has-mega-menu {
        position: static;
        &:hover > .megamenu {
          display: flex;
        }
      }
      &.has-mega-menu {
        .main-nav__sub-0 {
          top: 62px;
          border: solid 1px #292c31;
          background-color: #1e2024;
          width: 100%;
          display: -webkit-box;
          display: none;
          flex-wrap: wrap;
          left: 0;
          position: absolute;
          top: 62px;
          z-index: 1000;
          margin: 0;
          text-align: left;
          padding: 43px 55px;
          font-size: 15px;
          min-width: 240px;
          opacity: 0;
          -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
          -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
          transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
          z-index: 999;
        }
      }
      &:hover > .main-nav__sub-2 {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        opacity: 1;
        visibility: visible;
      }
      &:hover > .main-nav__sub-1 {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        opacity: 1;
        visibility: visible;
      }
      &:hover > .main-nav__sub-0 {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        opacity: 1;
        visibility: visible;
        transform-style: preserve-3d;
      }
      ul {
        display: none;
      }
      &:first-of-type {
        margin-left: 0;
      }
      &--mobile {
        display: none;
      }
      &.has-children {
        a:after {
          content: '\f107';
          display: inline-block;
          margin-left: 9px;
          top: -1px;
          position: relative;
          font-size: 10px;
          line-height: 1.2em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: 'Font Awesome 5 Free';
          font-weight: 700;
          color: #fff;
          font-style: normal;
        }
      }
    }
    &-link {
      font-style: normal;
      font-weight: 700;
      color: #fff;
      line-height: 62px;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      text-transform: uppercase;
      padding-left: 20px;
      padding-right: 20px;
      display: block;
      position: relative;
      padding: 0 34px;
      text-decoration: none;
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: transparent;
        -webkit-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
        z-index: -1;
      }
      &:hover {
        color: #fff;
        background-color: transparent;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          -webkit-transition: background-color 0.3s ease;
          transition: background-color 0.3s ease;
          z-index: -1;
          background-color: #38a9ff;
        }
      }
    }
  }
}
.main-nav__toggle,
.main-nav__toggle-2 {
  display: none;
}
.megamenu {
  display: none;
  .widget {
    color: #9a9da2;
    border: none;
    padding: 0;
  }
  .main-nav__list-item.has-children a:after {
    display: none;
  }
  ul[class*='megamenu-col-'] > li.megamenu-item {
    padding-left: 8px;
    padding-right: 8px;
  }
  &__title {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    letter-spacing: -0.03em;
    padding: 3px 0;
    margin-bottom: 19px;
    color: #fff;
    text-align: left;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    line-height: 1.2em;
  }
  .posts__title {
    font-size: 22px;
a:after{
  display: none;
}
}
  &__nav {
    display: block !important;
    min-width: auto;
    border: none;
    position: static;
    left: 100%;
    top: -1px;
    margin: 0;
    padding: 0;
    transform-origin: 0% 0%;
    transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
    &-item {
      display: block;
      white-space: nowrap;
      text-align: left;
      text-transform: uppercase;
      position: relative;
      padding: 0;
      margin: 0;
    }
    &-link {
      display: block;
      font-size: 11px;
      line-height: 18px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      text-align: left;
      padding: 5px 0;
      color: rgba(255, 255, 255, 0.4);
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      font-family: 'Montserrat', sans-serif;
      color: #78797c;
      &:hover {
        color: #fff;
      }
      &:after {
        display: none !important;
      }
    }
  }
}
.social-links {
  padding-left: 30px;
  display: flex;
  margin-bottom: 0;
  &__item {
    margin-right: 19px;
  }
  &__link {
    color: #6b6d70;
    transition: color 0.2s ease;
    i {
      color: #fff;
      font-size: 12px;
      margin-right: 5px;
      transition: color 0.2s ease;
    }
  }
}
.panel-toggle {
  background-color: #38a9ff;
  height: 62px;
  line-height: 62px;
  display: inline-block;
  width: 62px;
  min-width: 62px;
  vertical-align: middle;
  margin-left: 27px;
  position: relative;
  &__line {
    display: block;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 50%;
    left: 23px;
    right: 24px;
    width: 16px;
    transition: transform 0.3s;
    &:before,
    &:after {
      content: '';
      width: 100%;
      background-color: #fff;
      display: block;
      position: absolute;
      height: 2px;
    }
    &:before {
      top: -5px;
      transform-origin: top left;
      transition: transform 0.3s, width 0.3s, top 0.3s, -webkit-transform 0.3s;
    }
    &:after {
      bottom: -5px;
      transform-origin: bottom left;
      transition: transform 0.3s, width 0.3s, bottom 0.3s,
        -webkit-transform 0.3s;
    }
  }
}
.tooltip {
  .arrow {
    &:before {
      border-bottom-color: $brand;
      opacity: 1;
    }
  }
  &-inner {
    font-family: $ff-sec;
    background: $brand;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 3px;
  }
}

.hero {
  background-color: #1e2024;
  background-image: url(/images/hero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  .container {
    position: relative;
    z-index: 2;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 24px;
    background-image: linear-gradient(
        to right,
        rgba(236, 240, 246, 0.15) 4px,
        transparent 4px
      ),
      linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px),
      linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px),
      linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px),
      linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px),
      linear-gradient(to right, rgba(236, 240, 246, 0.15) 4px, transparent 4px),
      linear-gradient(to right, rgba(0, 0, 0, 0.1) 4px, transparent 4px),
      linear-gradient(to right, rgba(0, 0, 0, 0.11) 4px, transparent 4px);
    background-repeat: repeat-x;
    background-size: 36px 12px, 36px 15px, 36px 17px, 36px 20px, 36px 18px,
      36px 15px, 72px 15px, 108px 18px;
    background-position: 0 100%, 6px 100%, 12px 100%, 18px 100%, 24px 100%,
      30px 100%, 6px 100%, 24px 100%;
  }
  &__title {
    margin-bottom: 17px;
    position: relative;
    &--desktop {
      display: block;
    }
    &--mobile {
      display: none;
    }
    &-number {
      font-family: $ff-sec;
      color: #fff;
      font-size: 92px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 1em;
      display: block;
      position: absolute;
      left: 0;
      padding-right: 0.1em;
      bottom: -0.11em;
      transform: translateX(-100%);
    }
    &-text {
      font-style: normal;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      margin: 0;
      font-size: 26px;
      line-height: 1em;
      text-align: left;
      font-family: $ff-sec;
      &--lg {
        font-family: $ff-sec;
        font-size: 54px;
        line-height: 46px;
        display: block;
        color: $sec;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
  &__inner {
    display: flex;
    position: relative;
    width: 100%;
    color: #fff;
    align-items: flex-end;
  }
  &__item {
    &--photo {
      width: 370px;
    }
    &--details {
      padding: 48px 0;
      width: 460px;
    }
    &--stats {
      width: 340px;
      padding: 48px 0 88px 30px;
      &-inner {
        max-width: 100%;
      }
    }
  }
  &__photo {
    overflow: hidden;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
  }
  &__details {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    &-item {
      padding: 10px 40px 10px 0;
      padding-right: 50px;
      &--position {
        flex-basis: 22%;
        padding-right: 0;
      }
      &--goals {
        flex-basis: 50%;
        padding-right: 0;
      }
    }
    &-title {
      font-size: 10px;
      color: #9ed5ff;
      line-height: 1.2em;
      margin-bottom: 0.1em;
      font-weight: 400;
      text-transform: uppercase;
      font-family: $ff-sec;
    }
    &-value {
      font-family: $ff-sec;
      font-size: 15px;
      line-height: 1.2em;
      font-weight: 700;
    }
  }
  &__stats {
    padding: 25px 0 0 0;
    display: flex;
    &-item {
      width: 33.3%;
      &--top-padding {
        padding: 25px 0 0 0;
      }
    }
  }
}
.circular {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0;
  &__bar {
    width: 62px;
    height: 62px;
    min-width: 62px;
    position: relative;
    margin: 0 9px 0 0;
  }
  &__percents {
    color: #fff;
    font-size: 15px;
    font-family: $ff-sec;

    line-height: 1.2em;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 700;
    letter-spacing: -0.02em;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    text-transform: uppercase;
    small {
      display: block;
      font-size: 8px;
      line-height: 1.2em;
      color: #9ed5ff;
      font-weight: 400;
    }
  }
  canvas {
    width: 100% !important;
    height: 100% !important;
    vertical-align: top;
  }
  &__label {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin: 0;
    font-family: $ff-sec;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.2em;
    color: #9ed5ff;
    text-transform: uppercase;
    &-first {
      color: #fff;
      display: block;
      font-weight: 700;
    }
  }
}
.progress {
  background-color: rgba(255, 255, 255, 0.2);
  flex-basis: 100%;
  -webkit-box-ordinal-group: 3;
  order: 2;
  margin-left: 0 !important;
  margin-top: 12px;
  margin-bottom: 10px;
  flex-grow: 1;
  top: 0;
  border-radius: 3px;
  overflow: hidden;
  height: 6px;
  box-shadow: none;
  position: relative;
  &-item {
    margin-bottom: 20px;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    overflow: visible;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
  }
  &__label {
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1em;
    order: 0;
    color: #fff;
  }
  &__number {
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1em;
    margin-left: 10px;
    order: 1;
    font-weight: 700;
  }
  &__bar {
    &--success {
      background-color: #9fe900;
      color: #fff;
      border-radius: 3px;
      float: left;
      width: 0%;
      height: 100%;
      line-height: 6px;
      box-shadow: none;
    }
  }
}

.content-filter {
  border-bottom-color: #e4e7ed;
  background-color: #fff;
  border-bottom: 1px solid #e4e7ed;
  position: relative;
  z-index: 2;
  &__toggle {
    display: none;
  }
  &__list {
    border-left-color: #e4e7ed;
    border-right-color: #e4e7ed;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    text-align: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-bottom: none !important;
    -webkit-box-flex: 1;
    flex-grow: 1;
    border-left: 1px solid #e4e7ed;
    &:first-child {
      border-left: none;
    }
    &.is-active {
      background-color: transparent;
      .content-filter__link {
        color: #31404b;
        &:before {
          height: 4px;
          opacity: 1;
        }
      }
    }
  }
  &__link {
    display: block;
    letter-spacing: -0.02em;
    font-style: normal;
    line-height: 1.2em;
    color: #9a9da2;
    padding: 18px 20px 18px 20px;
    -webkit-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
    position: relative;
    z-index: 1;
    font-size: 13px;
    text-transform: uppercase;
    font-family: $ff-sec;
    font-weight: 700;
    letter-spacing: -0.02em;
    padding-top: 28px;
    padding-bottom: 28px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    small {
      display: block;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2em;
      color: #9a9da2;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      opacity: 0;
      background-color: #38a9ff;
      -webkit-transition: opacity height 0.2s ease-out;
      transition: opacity height 0.2s ease-out;
    }
    &:hover {
      color: #31404b;
    }
  }
}
.site-content {
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  padding: 60px 0;
}

.card {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 15px;
  &--clean {
    background: none;
    border: none;
    .card__header + .card__content {
      padding: 15px 0 0 0;
    }
  }
  &--has-table {
    .card__content {
      padding: 0;
      margin: 0 !important;
    }
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 22px 23px;
    border-radius: 4px 4px 0 0;
    position: relative;
    border-bottom: 1px solid #e4e7ed;
    overflow: hidden;
    background-color: #fff;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: #38a9ff;
      border-radius: 3px 0 0 0;
    }
    &-caption {
      font-size: 16px;
      line-height: 1.2em;
      letter-spacing: -0.02em;
      margin-bottom: 0;
      text-transform: uppercase;
      font-style: normal;
      padding-right: 1em;
      padding-top: 5px;
      padding-bottom: 5px;
      vertical-align: middle;
      font-style: normal;
      -webkit-box-flex: 1;
      flex-grow: 1;
      font-weight: 700;
      color: #31404b;
      font-family: 'Montserrat', sans-serif;
    }
  }
  &__content {
    padding: 0;
    margin: 0 !important;
    position: relative;

    padding: 24px 24px;
    position: relative;

    .table {
      margin-bottom: 0;
    }
  }
}
.table {
  color: #9a9da2;
  width: 100%;
  max-width: 100%;
  thead {
    tr {
      th {
        border-bottom-color: #e4e7ed;
        color: #31404b;
        font-family: 'Montserrat', sans-serif;
        vertical-align: middle;
        text-align: center;
        border-bottom: 1px solid #e4e7ed;
        font-weight: 700;
        text-transform: uppercase;
        padding: 13px 10px;
        font-size: 11px;
        line-height: 1.42857143em;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 13px 10px;
        font-size: 11px;
        line-height: 1.42857143em;
        a {
          color: #9a9da2;
        }
      }
    }
  }

  &__date {
    padding-left: 23px !important;
  }
}
.table > thead:first-child > tr:first-child > th,
table > thead:first-child > tr:first-child > th {
  border-top: 0;
}
.table-hover > tbody > tr,
.table-hover > tbody > tr.highlighted {
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
table > thead > tr > th,
.table > thead > tr > th,
table > tbody > tr > th,
.table > tbody > tr > th,
table > tfoot > tr > th,
.table > tfoot > tr > th,
table > thead > tr > td,
.table > thead > tr > td,
table > tbody > tr > td,
.table > tbody > tr > td,
table > tfoot > tr > td,
.table > tfoot > tr > td {
  border-top-color: #e4e7ed;
  font-family: 'Montserrat', sans-serif;
}
.column-container {
  padding-left: 0;
  padding-right: 0;
}
.column-inner {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.team-meta {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  &__logo {
    width: 35px;
    height: 30px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 0;
    img {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  &__info {
    display: inline-block;
    vertical-align: middle;
  }
  &__name {
    font-size: 12px;
    line-height: 1.2em;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: none;
  }
  &__place {
    display: block;
    font-size: 9px;
    line-height: 1.2em;
    font-family: 'Montserrat', sans-serif;
    color: #9a9da2;
  }
}

.btn {
  border: 1px solid transparent;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  vertical-align: middle;
  padding: 13px 42px;
  font-size: 11px;
  line-height: 1.5em;
  border-radius: 2px;
  &-outline.btn-default {
    border-color: #dbdfe6;
    color: #9a9da2;
    background-color: transparent;
  }
  &-xs {
    padding: 5px 13px;
    font-size: 9px;
    line-height: 1.5em;
    border-radius: 2px;
  }
}
.label {
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  padding: 0.5em 1.1em;
  font-size: 8px;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  text-transform: uppercase;
}
.posts {
  &__cat-label {
    background-color: #1892ed;
    display: block;
    float: left;
    margin-bottom: 4px;
    font-style: normal;
  }
  &__item--card,
  &-item--card:last-child {
    margin-bottom: 15px;
  }

  &__item--card {
    .posts {
      &__thumb:first-child {
        overflow: hidden;
        border-radius: 4px 4px 0 0;
      }
      &__thumb {
        float: none;
        margin: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        &:hover img {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        img {
          width: 100%;
          height: auto;
          -webkit-transition: -webkit-transform 0.5s ease;
          transition: -webkit-transform 0.5s ease;
          transition: transform 0.5s ease;
          transition: transform 0.5s ease, -webkit-transform 0.5s ease;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
      }
      &__cat {
        display: block;
        position: absolute;
        left: 23px;
        top: 23px;
        overflow: hidden;
        margin-bottom: 4px;
        padding-right: 5px;
        z-index: 1;
        &-label {
          font-size: 10px;
          padding-top: 0.6em;
          margin-bottom: 0.6em;
          font-family: 'Montserrat', sans-serif;
          color: #fff;
          &--category-1 {
            background-color: #1892ed;
          }
        }
      }
      &__inner {
        padding-top: 18px;
        position: relative;
      }
      &__cta {
        display: block;
        position: absolute;
        top: 0;
        right: 23px;
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #1892ed;
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          background-color: #fff;
          -webkit-transition: -webkit-transform 0.25s ease;
          transition: -webkit-transform 0.25s ease;
          transition: transform 0.25s ease;
          transition: transform 0.25s ease, -webkit-transform 0.25s ease;
        }
        &:before {
          width: 17px;
          height: 3px;
        }
        &:after {
          width: 3px;
          height: 17px;
        }
        &:hover {
          &:before,
          &:after {
            -webkit-transform: translate(-50%, -50%) rotate(90deg);
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
      &__date {
        font-size: 11px;
        line-height: 1.2em;
        font-family: 'Montserrat', sans-serif;
        display: inline-block;
        text-transform: uppercase;
      }
      &__title {
        margin-top: 10px;
        font-size: 24px;
        line-height: 1em;
        margin-bottom: 0.2em;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: -0.03em;

        font-family: $ff-sec;
        a {
          color: #31404b;
          transition: color 0.2s ease-in-out;
          font-weight: 700;
          &:hover {
            color: #4f6779;
          }
        }
      }
      &__excerpt {
        padding: 23px 0 8px 0;
      }
      &__footer {
        padding: 17px 24px;
        border-top: 1px solid #e4e7ed;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        border-top: 1px solid #e4e7ed;
      }
    }
    .post-author {
      display: flex;
      align-items: center;
      width: 50%;
      text-align: left;
      &__avatar {
        margin: 0 14px 0 0;
        width: 24px;
        height: 24px;
        margin-right: 7px;
        vertical-align: middle;
        border-radius: 50%;
        overflow: hidden;
      }
      &__info {
        position: relative;
        display: inline-block;
        vertical-align: middle;
      }
      &__name {
        font-size: 10px;
        text-transform: uppercase;
        color: #9a9da2;
        margin-bottom: 0.2em;
        font-weight: 400;
        font-style: normal;
      }
    }
    .post__meta {
      width: 50%;
      text-align: right;
    }
    .meta__item {
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 1.2em;
      display: inline-block;
      vertical-align: middle;
      & + .meta__item {
        margin-left: 15px;
      }
      a {
        transition: color 0.2s ease;
        color: #9a9da2;
        &:hover {
          color: #31404b;
        }
      }
      &:before {
        margin-right: 8px;
        font-size: 13px;
        line-height: 1em;
        vertical-align: middle;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-family: 'simple-line-icons';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 4px;
        position: relative;
        top: -1px;
      }
      &--views:before {
        content: '\e087';
      }
      &--likes .meta-like {
        vertical-align: middle;
        margin-right: 6px;
        font-size: 12px;
      }
      &--comments > a {
        display: inline-block;
        &:before {
          font-size: 13px;
          line-height: 1em;
          vertical-align: middle;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          font-family: 'simple-line-icons';
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 4px;
          position: relative;
          top: -1px;
          content: '\e07d';
          font-size: 12px;
          margin-right: 8px;
        }
      }
    }
  }
}
.posts--simple-list--lg {
  &.posts--simple-list--lg--clean .posts__item {
    border-bottom: none;
  }
  .posts__cat {
    margin-bottom: 10px;
    &-label {
      font-size: 10px;
    }
  }
  .posts__date {
    margin-top: 0.7em;
    font-size: 10px;
    line-height: 1.2em;
    vertical-align: middle;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    display: inline-block;
  }
  .posts__excerpt {
    margin-top: 6px;
    font-size: 15px;
    line-height: 26px;
  }
  .posts__footer {
    padding-top: 17px;
    border-top: none;
    .post-author {
      float: left;
      width: 50%;
      &__avatar {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        vertical-align: middle;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 14px 0 0;
        display: inline-block;
      }
      &__info {
        position: relative;
        display: inline-block;
        vertical-align: middle;
      }
      &__name {
        color: #9a9da2;
        font-size: 10px;
        text-transform: uppercase;
        color: #9a9da2;
        font-weight: 400;
        margin-bottom: 0.2em;
      }
    }
  }
  .post__meta {
    width: 50%;
    float: right;
    text-align: right;
  }
  .meta__item {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1.2em;
    display: inline-block;
    vertical-align: middle;
    &.meta__item--comments {
      &:before {
        margin-right: 8px;
        line-height: 1em;
        vertical-align: middle;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-family: 'simple-line-icons';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 4px;
        position: relative;
        top: -1px;
        content: '\e07d';
        font-size: 12px;
      }
    }
    .meta-like {
      vertical-align: middle;
      margin-right: 6px;
      font-size: 12px;
    }
    & + .meta__item {
      margin-left: 15px;
    }
    a {
      color: #9a9da2;
      display: inline-block;
      transition: color 0.2s ease;
      &:after {
        // display: none;
      }
    }
  }

  .posts__title {
    font-size: 22px !important;
    line-height: 1em;
    margin-bottom: 0.2em;

    a {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
}
.posts--simple-list {
  .posts__inner {
    overflow: hidden;
  }
}
.btn-social-counter {
  display: block;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  min-height: 65px;
  position: relative;
  overflow: hidden;
  background-clip: padding-box;
  padding: 20px 60px 18px 86px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 1px solid #e4e7ed;
  &:hover {
    color: #fff;
  }
  & + .btn-social-counter {
    margin-top: 10px;
  }
  &--fb {
    background-color: #4d6baa;
    .btn-social-counter__icon {
      background-color: #43609c;
    }
    &:hover {
      background-color: #5c78b5;
    }
  }
  &--twitter {
    background-color: #3fcef2;
    &:hover {
      background-color: #57d4f4;
    }
    .btn-social-counter__icon {
      background-color: #38c2e5;
    }
  }
  &__icon {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 68px;
    background-color: rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 18px;
    .fa {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  &__title {
    font-size: 13px;
    line-height: 1.2em;
    color: #fff;
    margin-bottom: 1px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
  }
  &__count {
    display: block;
    font-size: 10px;
    line-height: 1.2em;
    color: #fff;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
  }
  &__add-icon {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid #fff;
    right: 23px;
    top: 50%;
    margin-top: -13px;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: #fff;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: -webkit-transform 0.2s ease-in-out;
      transition: -webkit-transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    }
    &:before {
      width: 8px;
      height: 2px;
    }
    &:after {
      width: 2px;
      height: 8px;
    }
  }
}
.widget {
  &-social {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  &--sidebar {
    margin-bottom: 15px;
  }
  &-player {
    &__details {
      border-color: #e4e7ed;
      display: flex;
      flex-wrap: wrap;
      border-style: solid;
      border-width: 1px 0 0 1px;
      border-color: #e4e7ed;
      margin: -1px;
      &-item {
        border-color: #e4e7ed;
        padding: 12px 24px;
        -webkit-box-flex: 0;
        flex: 0 1 50%;
        max-width: 50%;
        border-style: solid;
        border-width: 0 1px 1px 0;

        &:first-child {
          border-left: none;
        }
      }
      &-desc-wrapper {
        display: table;
        width: 100%;
      }
      &-holder {
        display: table-cell;
        vertical-align: middle;
        width: 70%;
      }
      &-label {
        font-family: 'Montserrat', sans-serif;
        color: #31404b;
        font-size: 12px;
        display: block;
        font-family: 'Montserrat', sans-serif;
        color: #31404b;
        font-size: 10px;
        line-height: 1.2em;
      }
      &-desc {
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-size: 8px;
        line-height: 1.2em;
        font-size: 9px;
      }
      &-value {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
        width: 30%;
        font-family: 'Montserrat', sans-serif;
        color: #31404b;
        font-size: 11px;
        line-height: 1.2em;
        font-family: 'Montserrat', sans-serif;
        color: #31404b;
      }
    }
  }
  &__content-inner {
    padding: 24px 24px;
  }
  &__content--bottom-decor {
    padding-bottom: 30px;
    background-image: linear-gradient(
        to right,
        rgba(236, 240, 246, 0.6) 8px,
        transparent 8px
      ),
      linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px),
      linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px),
      linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px),
      linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px),
      linear-gradient(to right, rgba(236, 240, 246, 0.6) 8px, transparent 8px),
      linear-gradient(to right, rgba(0, 0, 0, 0.1) 8px, transparent 8px),
      linear-gradient(to right, rgba(0, 0, 0, 0.11) 8px, transparent 8px);
    background-repeat: repeat-x;
    background-size: 72px 24px, 72px 30px, 72px 34px, 72px 40px, 72px 37px,
      72px 29px, 144px 30px, 216px 37px;
    background-position: 0 100%, 12px 100%, 24px 100%, 36px 100%, 48px 100%,
      60px 100%, 12px 100%, 48px 100%;
  }
}
.card .newslog {
  margin-top: -24px;
  margin-bottom: -24px;
}
.newslog {
  list-style: none;
  padding: 0;
  margin: 0;
  &__item {
    margin-left: -24px;
    margin-right: -24px;
    border-bottom: 1px solid #e4e7ed;
    font-family: 'Montserrat', sans-serif;
    padding: 24px 24px;
    font-size: 11px;
    line-height: 17px;
    strong {
      color: #31404b;
      font-weight: 400;
    }
    &-inner {
      position: relative;
      padding-left: 36px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 6px;
        background-repeat: no-repeat;
        background-image: url(/images/icon-trophy.svg);
      }
    }
    &--injury {
      .newslog__item-inner {
        &:before {
          background-color: #ff5353;
          border: 1px solid #e54444;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-image: linear-gradient(to left, #fff, #fff),
            linear-gradient(to left, #fff, #fff);
          background-size: 8px 2px, 2px 8px;
          background-position: 3px 6px, 6px 3px;
        }
      }
    }
    &--join .newslog__item-inner:before {
      background-image: url(/images/icon-join.svg);
      background-size: 8px 12px;
      width: 8px;
      height: 12px;
    }
    &--award {
      .newslog__item-inner:before {
        background-image: url(/images/icon-trophy.svg);
        background-size: 18px 19px;
        width: 18px;
        height: 19px;
      }
    }
  }
  &__date {
    color: rgba(154, 157, 162, 0.4);
  }
}

.footer {
  font-size: 14px;
  line-height: 22px;
  color: rgba(154, 157, 162, 0.6);
  & > .row {
    display: flex;
    align-items: center;
    text-align: center;
  }
  &-logo {
    width: auto;
    margin: 0;
    padding: 30px 0;
  }
}
.footer-col-inner {
  text-align: center;
  .widget__title {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    margin-bottom: 3.5em;
    text-transform: uppercase;
    font-weight: 700;
  }
}
.footer-widgets {
  background: #1e2024;
  position: relative;
  // display: flex;
  // align-items: center;
  &__inner {
    padding: 40px 0 40px;
    position: relative;
    z-index: 4;
  }
}
.posts--simple-list {
    .posts__inner {
        overflow: hidden;
    }
    .posts__date{
        font-size: 10px;
        line-height: 1.2em;
        vertical-align: middle;
        display: inline-block;
        margin-top: 0.7em;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-weight: 400;
    }
    .posts__cat {
        display: block;
        overflow: hidden;
        margin-bottom: 4px;
        padding-right: 5px;
        z-index: 1;
    }
    .posts__title {
        font-size: 12px;

        margin-bottom: 0;
        line-height: 1em;
        text-transform: uppercase;
        letter-spacing: -0.03em;
    a{
        color: #fff;
        font-weight: 700;
    }}
  .posts__item {
    overflow: hidden;
    margin-bottom: 18px;

    &--list-item{
        display: flex;
    }
  }
  .posts__thumb {
    border-radius: 0;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 13px;
    &--hover > a {
      position: relative;
      display: block;
    }
    &:before {
      border-radius: 0;
    }
  }
  .posts__thumb--hover > a:before,
  .posts__thumb--hover > a:after {
    background-color: #fff;
  }
  .posts__thumb--hover > a:after {
    height: 18px;
    width: 4px;
    margin: 0;
  }

  .posts__thumb--hover > a:before {
    height: 4px;
    width: 18px;
  }
  .posts__thumb--hover > a:before,
  .posts__thumb--hover > a:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #fff;
    overflow: hidden;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    z-index: 2;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .posts__thumb--hover:hover > a:before,
  .posts__thumb--hover:hover > a:after {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  .posts__thumb {
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #38a9ff;
      color: #fff;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      pointer-events: none;
    }
  }
  .posts__thumb {
    overflow: hidden;
    position: relative;
    &:hover:before {
      background-color: #1892ed;
      opacity: 0.8;
    }
  }
}
